import { useSelector } from 'react-redux';

import themes from '../assets/themes';

const Modal = (props) => {
  const theme = useSelector((state) => state.screen.theme);

  return (
    <div
      className={`fixed overflow-hidden z-20 flex items-center justify-center ${props.className}`}
      style={{
        backgroundColor: props.bgColor ?? themes[theme].modalColor,
        left: props.active ? 0 : '50%',
        top: props.active ? 0 : '50%',
        width: props.active ? '100vw' : 0,
        height: props.active ? 'calc(var(--vh, 1vh) * 100)' : 0,
      }}
      onClick={(e) => {
        // e.stopPropagation();
        props?.onClick?.();
      }}
    >
      <div className="flex-grow flex items-center justify-center w-full h-full relative">
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
