const changeImageURLDomain = (urlString) => {
    let url;

    try {
        url = new URL(urlString);
    } catch (_) {
        return urlString;
    }

    if (url.host === "depo24-images.s3.ap-south-1.amazonaws.com") {
        url.host = process.env.REACT_APP_depo24_images_cloudfront_Domain;
    } else if (url.host === "depo24-images-shopify.s3.ap-south-1.amazonaws.com") {
        url.host = process.env.REACT_APP_depo24_images_shopify_cloudfront_Domain;
    }

    return url.toString();
}

export default changeImageURLDomain;