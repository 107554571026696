import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment';
import changeImageURLDomain from "../utilities/changeImageURLDomain";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AttachmentIcon from '@mui/icons-material/Attachment';

const EstimateItem = (props) => {
    const screen = useSelector(state => state.screen);
    const navigate = useNavigate();
    const { estimate } = props;

    const image = estimate.products?.[0]?.mainImageUrl ?? process.env.REACT_APP_Image_Comming_Soon;
    const statusColor = {
        'Pending': '#b09609',
        "Complete" : '#7C9F33',
        "Rejected": '#e54545'
    }

    const statusBackgroundColor = {
        "Complete": '#E9F1DA',
        "Rejected" : '#E9F1DA'
    }

    if (screen.screenWidth > 600)
        return (
            <div 
                className="rounded-2xl overflow-hidden" 
                style={{ boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.15)', cursor:'pointer' }} 
                onClick={() => navigate(`/profile/estimates/${estimate?.estimateId}`)}
            >
                <div className="flex flex-col gap-6 p-6">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center text-xs">
                            <p className="mx-2" style={{borderRight:'2px solid lightgray', width:10, height: 15 }}></p>
                            <p>Requested, {moment(estimate?.createdAt).format('MMM DD, YYYY h:mm:ss A')}</p>
                        </div>
                        {estimate.products.length > 1 ? <div className="mt-4 text-xs rounded-2xl bg-slate-200 px-3 py-2">More Items Exist In this Estimate</div> : null}
                    </div>

                    <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                            <div 
                                className="flex gap-1 items-center h-32 w-32 justify-center px-3 rounded-full" 
                                style={{ backgroundColor: statusBackgroundColor[estimate?.status] ? statusBackgroundColor[estimate?.status] : '#FFF2E5' }}
                            >
                                <p 
                                    className="w-[10px] h-[10px] rounded-full" 
                                    style={{ backgroundColor: statusColor[estimate?.status] ? statusColor[estimate?.status]: '#E0822A' }}
                                ></p>
                                <p style={{ color: statusColor[estimate?.status] ? statusColor[estimate?.status]: '#E0822A'}}>{estimate?.status}</p>
                            </div>
                            <div className="flex flex-col gap-1">
                                <div className="flex gap-2 text-[16px] text-[#243750] font-[500]">
                                    <p>Order Name:</p>
                                    <p>{estimate?.orderName || '( Not yet created )'}</p>
                                </div>
                                <p className="text-[14px]">{estimate?.products[0]?.productName ?? ''}</p>

                                <div className="flex gap-12 items-center">
                                    <div className="flex flex-row gap-2 items-center">
                                        <ShoppingCartIcon style={{fontSize:'16px', color:'#243750'}} />
                                        <p className="text-[12px]">Products:</p>
                                        <p className="text-[12px]">{estimate?.products?.length}</p>
                                    </div>

                                    <div className="flex flex-row gap-2 items-center">
                                        <AttachmentIcon style={{fontSize:'16px', color:'#243750'}} />
                                        <p className="text-[12px]">Attachments:</p>
                                        <p className="text-[12px]">{estimate?.attachments?.length || 0}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div><ChevronRightIcon style={{fontSize:'35px', color:'#243750'}} /></div>
                    </div>
                </div>
            </div>
        )

    return (
        <Link to={estimate.estimateId} className="w-full border-b border-gray-500 p-4 flex items-start gap-8">
            <img src={changeImageURLDomain(image)} alt="" className="w-20" />
            <div className="text-sm flex-grow flex flex-col items-start gap-1">
                <p>Order<span className="font-medium">{estimate.orderName|| '_______________'}</span></p>
            </div>
            <div className="flex gap-12 items-center">
                <div className="flex flex-row gap-2 items-center">
                    <ShoppingCartIcon style={{fontSize:'16px', color:'#243750'}} />
                    <p className="text-[12px]">Products:</p>
                    <p className="text-[12px]">{estimate?.products?.length}</p>
                </div>
                <div className="flex flex-row gap-2 items-center">
                    <AttachmentIcon style={{fontSize:'16px', color:'#243750'}} />
                    <p className="text-[12px]">Attachments:</p>
                    <p className="text-[12px]">{estimate?.attachments?.length || 0}</p>
                </div>
            </div>
        </Link>
    )
}

export default EstimateItem;