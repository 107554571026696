import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { encodeUrlQueryParams } from '../utilities/encodeDecodeUrlParams';
// import { getAnalytics, logEvent } from 'firebase/analytics';

const HeaderL1 = (props) => {
  // const analytics = getAnalytics();

  const { l1, theme, themes } = props;
  const screen = useSelector((state) => state.screen);
  const auth = useSelector((state) => state.auth);
  const [hovered, setHovered] = useState(false);

  const handleDataLayer = (l1, l2, l3) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
      'event':'header_interaction',
      'header_text': l2,
      'sub_header_text': l3,
      'section_name': l1,
      'logged_in_medium': auth?.user ? 'mobile' : 'NA',
      'user_status': auth?.user ? 'logged_in' : 'guest',
      'custom_user_id': auth?.user ? auth?.user?.pgId : 'NA'
    });
	}

  return (
    <div
      key={l1._id + 'Header'}
      className="text-sm py-2 px-1 transition-all ease-linear border-b-2"
      style={{
        borderColor: !hovered
          ? 'transparent'
          : l1.color ?? themes[theme].primaryColor,
      }}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      // onClick={() => {
      //     setHovered(true)
      // }}
    >
      <Link
        to={`/products?text=${encodeUrlQueryParams(
          l1.title
        )}&filters=[{"field":"l1","value":"${encodeUrlQueryParams(
          l1.title
        )}"}]`}
        className="font-medium"
        onClick={() => {
          // logEvent(analytics, 'header_l1_clicked', {
          //   title: l1.title,
          // });
          handleDataLayer(l1?.title, 'NA', 'NA')
        }}
      >
        {l1.title}
      </Link>

      <div
        className={`absolute left-0 right-0 top-full border rounded shadow-lg bg-white z-30 h-fit p-4 ${
          hovered ? '' : 'hidden'
        }`}
        style={{
          maxHeight: 'calc((var(--vh, 1vh) * 60)',
        }}
      >
        <div
          className={`grid grid-flow-row grid-cols-4 minlgc:grid-cols-5 gap-8`}
        >
          {l1?.l2s
            ? l1.l2s
                .slice(0, screen.screenWidth >= 1160 ? 10 : 8)
                .map((l2, index) => {
                  const l3s = l2.l3s.slice(0, 5);
                  const vm = l2.l3s.length > 5;
                  return (
                    <div
                      key={l2._id + 'Header'}
                      className={`${
                        index % 2 !== 0 ? 'bg-gray-100' : ''
                      } p-4 rounded`}
                    >
                      <Link
                        to={`/products?text=${encodeUrlQueryParams(
                          l2.title
                        )}&filters=[{"field":"l2","value":"${encodeUrlQueryParams(
                          l2.title
                        )}"}]`}
                        className="text-sm"
                        style={{
                          color: l1.color ?? themes[theme].primaryColor,
                        }}
                        onClick={() => {
                          // logEvent(analytics, 'header_l2_clicked', {
                          //   l1: l1.title,
                          //   title: l2.title,
                          // });
                          handleDataLayer(l1?.title, l2?.title, 'NA')
                        }}
                      >
                        {l2.title}
                      </Link>

                      <div
                        className="flex flex-col gap-2 items-start mt-2 overflow-y-auto extra-thin-scrollbar pr-2"
                        style={{
                          maxHeight: '200px',
                        }}
                      >
                        {l3s.map((l3, index) => {
                          if (index === 0) {
                            return null;
                          }

                          return (
                            <Link
                              key={l3._id + 'Header'}
                              to={`/products?text=${encodeUrlQueryParams(
                                l3.title
                              )}&filters=[{"field":"l3","value":"${encodeUrlQueryParams(
                                l3.title
                              )}"}]`}
                              className="text-xs"
                              onClick={() => {
                                // logEvent(analytics, 'header_l3_clicked', {
                                //   l1: l1.title,
                                //   l2: l2.title,
                                //   title: l3.title,
                                // });
                                handleDataLayer(l1?.title, l2?.title, l3?.title)
                              }}
                            >
                              {l3.title}
                            </Link>
                          );
                        })}
                        {vm ? (
                          <Link
                            to={`/categories?l2=${l2.title
                              .replaceAll(' ', '%20')
                              .replaceAll('&', '%26')}`}
                            className="text-xs font-medium"
                            style={{
                              color: l1.color ?? themes[theme].primaryColor,
                            }}
                            onClick={() => {
                              // logEvent(
                              //   analytics,
                              //   'header_view_l2_view_more_clicked',
                              //   {
                              //     l1: l1.title,
                              //     title: l2.title,
                              //   }
                              // );
                            }}
                          >
                            View More
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  );
                })
            : Array.from({ length: 5 }, (_, index) => (
                <div
                  key={index}
                  className={`p-4 h-36 w-56 mx-auto rounded-xl shadow-lg space-y-4 ${
                    index % 2 !== 0 ? 'bg-gray-400' : 'bg-white'
                  }`}
                >
                  <div className="animate-pulse">
                    <div className="h-6 bg-gray-200 rounded mb-4 w-3/4"></div>
                    <div className="space-y-2">
                      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
        {screen.screenWidth >= 1160 && l1?.l2s?.length > 10 ? (
          <Link
            to={`/categories?l2=${l1?.l2s[10].title
              .replaceAll(' ', '%20')
              .replaceAll('&', '%26')}`}
            className="text-xs font-medium"
            style={{
              color: l1.color ?? themes[theme].primaryColor,
            }}
            onClick={() => {
              // logEvent(analytics, 'header_view_l2_view_more_clicked', {
              //   l1: l1.title,
              //   title: l1?.l2s[10].title,
              // });
            }}
          >
            View More
          </Link>
        ) : null}

        {screen.screenWidth < 1160 && l1?.l2s?.length > 8 ? (
          <Link
            to={`/categories?l2=${l1?.l2s?.[8].title
              .replaceAll(' ', '%20')
              .replaceAll('&', '%26')}`}
            className="text-xs font-medium"
            style={{
              color: l1.color ?? themes[theme].primaryColor,
            }}
            onClick={() => {
              // logEvent(analytics, 'header_view_l1_view_more_clicked', {
              //   l2: l1.title,
              // });
            }}
          >
            View More
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default HeaderL1;

