export const encodeUrlQueryParams = (value) => {
    try{
        if(!isNaN(parseInt(value))){
            return parseInt(value);
        }

        if(Array.isArray(value)){
            return JSON.stringify(value)?.replaceAll('&','%26')
        }
        
        return value.replaceAll(' ', '%20').replaceAll('&','%26')
    }
    catch(error){
        console.log("Error in encodeURL : ",error)
        return ''
    }
}