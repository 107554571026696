import { useEffect,useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { set as setScreen } from '../store/screenSlice';
import themes from '../assets/themes';

import Availability from './availability';
import SearchBar from './searchBar';
// import HamBurgerMenu from './hamburgerMenu';
import axiosInstance from '../utilities/axiosInstance';
import { set as setHome } from '../store/homeSlice';
import HeaderL1 from '../components/headerL1';
import Logo from '../assets/logo.svg';
import CartButton from '../components/cartButton';
import PartnerButton from '../components/partnerButton';
import ContactUs from '../components/contactUsButton';
import DownloadAppButton from '../components/downloadAppButton';
import BrowseByBrands from '../components/browseByBrands';
import HoverNav from './hoverNav';
import { useNavigate } from 'react-router-dom';
import NavLinkShimmer from '../components/Shimmer/navLinkShimmer';

const Header = (props) => {
    const dispatch = useDispatch();
    const screen = useSelector((state) => state.screen);
    const auth = useSelector((state) => state.auth);
    const home = useSelector((state) => state.home);
    const ref = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const headerHeight = ref.current.offsetHeight;
        dispatch(setScreen({ headerHeight }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen.screenWidth, ref?.current?.offsetHeight]);

    const getL1s = async () => {
        axiosInstance.get('/others/l1s').then((res) => {
            let l1s = res.data.data;
            let l2s = [];
            let l3s = [];

            for (let i = 0; i < l1s.length; i++) {
                l2s.push(...l1s[i]['l2s']);
            }

            for (let i = 0; i < l2s.length; i++) {
                l3s.push(...l2s[i]['l3s']);
            }

            dispatch(
                setHome({
                    l1s: l1s,
                    l2s: l2s,
                    l3s: l3s,
                    loadingL3Products: false,
                    l3Index: 0,
                    l3Collections: [],
                })
            );
        });
    };

    const getOnlyL1s = async () => {
      axiosInstance.get('/others/all/l1s').then((res) => {
          let l1s = res.data.data;
        dispatch(
          setHome({
            l1s: l1s,
          })
        );
      });
    };


    useEffect(() => {
      if (!home.l1s && !home.loading && !home.loaded) {
        getOnlyL1s();
      }
      if (!home.l1s && !home.loading && !home.loaded) {
        getL1s();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDataLayer = () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event':'header_interaction',
        'header_text': 'NA',
        'sub_header_text': 'NA',
        'section_name': 'Catalogues',
        'logged_in_medium': auth?.user ? 'mobile' : 'NA',
        'user_status': auth?.user ? 'logged_in' : 'guest',
        'custom_user_id': auth?.user ? auth?.user?.pgId : 'NA'
      });
    }

    return (
        <header
            ref={ref}
            className="fixed z-10 bg-white shadow-md pb-1"
            style={{
                left: 0,
                right: 0,
                top: 0,
            }}
        >
        <div className="w-full  border-t-8 border-[#F46F2C]">
          <div className="w-full mt-[2px]  border-t-[3px] border-[#153D6F]">
            <div className="container flex justify-between items-center gap-4" style={screen.screenWidth <= 1070 ? {
                width: '100%',
                gap: '1rem'
            } : {}}>
                <div className='flex items-center gap-4'>
                    <Link to="/" className="mdc:hidden" aria-label='Logo'>
                        <img src={Logo} alt='Logo' />
                    </Link>
                    {/* <HamBurgerMenu /> */}
                    {auth.authenticated ? <div
                        className="cursor-pointer relative [&>nav]:hover:flex"
                        style={{
                            // color: themes[screen.theme].headerTextColor,
                            borderColor: 'transparent',
                        }}
                    >
                        <p className="text-sm">
                            Welcome Back,
                        </p>
                        <p className="font-medium">
                            {auth.user.display_name ?? (auth.user.first_name + ' ' + auth.user.last_name)}
                        </p>
                        <HoverNav />
                    </div> : <div
                        onClick={() => {
                            dispatch(setScreen({ showAuth: true }));
                        }}
                        className="cursor-pointer block"
                        style={{
                            // color: themes[screen.theme].headerTextColor,
                        }}
                    >
                        <p className="text-sm">
                            Hi There,
                        </p>
                        <p className="font-medium">
                            Please Login
                        </p>
                    </div>}

                    <div className='mdc:hidden'>
                        <CartButton />
                    </div>
                </div>

                <div className="mdc:hidden flex-grow">
                    <SearchBar text={props?.text} />
                </div>

                <div className='flex items-center gap-4'>
                    <Availability />

                    <div className='mdc:hidden'>
                        <PartnerButton />
                    </div>
                </div>
            </div>

            <div className="container hidden mdc:block !pt-0">
                <SearchBar text={props?.text} />
            </div>

            <div
              className="mdc:hidden !py-0 container flex items-end gap-4 justify-end relative"
              style={
                screen.screenWidth <= 1070
                  ? {
                      width: '100%',
                      gap: '1rem',
                    }
                  : {}
              }
            >
              <div className="flex-grow">
                <div className="flex items-center gap-4 hide-scrollbar overflow-x-scroll">
                  {home.l1s && home?.l1s?.length > 0 ? (
                    <div className="mdc:hidden">
                      <BrowseByBrands />
                    </div>
                  ): (
                    <div className="animate-pulse flex">
                      <div className="bg-gray-300 rounded-md h-6 w-14"></div>
                    </div>
                  )}

                  <div
                    className="bg-gray-300"
                    style={{ width: '1.5px', height: '24px' }}
                  ></div>

                  {home.l1s && home?.l1s?.length > 0 ? (
                    home?.l1s.map((l1, index) => (
                      <HeaderL1
                        key={l1._id + 'Header'}
                        l1={l1}
                        theme={screen.theme}
                        themes={themes}
                        index={index}
                      />
                    ))
                  ) : (
                    <div className="animate-pulse flex py-2 space-x-4">
                      <div className="bg-gray-300 rounded-md h-6 w-28"></div>
                      <div className="bg-gray-300 rounded-md h-6 w-32"></div>
                      <div className="bg-gray-300 rounded-md h-6 w-20"></div>
                    </div>
                  )}

                  <div
                    className="bg-gray-300"
                    style={{ width: '1.5px', height: '24px' }}
                  ></div>
                  
                  <div className="text-sm py-2 px-1 transition-all ease-linear flex items-center gap-2">
                    <button onClick={
                      () => {
                        navigate('/catalogues')
                        handleDataLayer()
                      }
                    } type='button' className='font-medium'>Catalogues</button>
                  </div>
                </div>
              </div>

              <div className="mdc:hidden">
                <ContactUs />
              </div>

                <div className='mdc:hidden'>
                    <DownloadAppButton />
                </div>
            </div>
          </div>
        </div>
        </header>
    );
};

export default Header;