import { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot as fasLocationDot } from '@fortawesome/free-solid-svg-icons';

import themes from '../assets/themes';
import { set as setScreen } from '../store/screenSlice';

library.add(fasLocationDot);

const Availability = () => {
	const screen = useSelector((state) => state.screen);
	const theme = screen.theme;
	const availability = useSelector((state) => state.availability);
	const dispatch = useDispatch();

	const ref = useRef();

	return (
		<div
			ref={ref}
			onClick={() => {
				dispatch(setScreen({ showCheckPincode: !screen.showCheckPincode }));
			}}
			className="border rounded-md py-2 px-4 flex justify-center items-center cursor-pointer gap-2 w-fit !h-10"
			style={{
				// backgroundColor: themes[theme].bgColor,
				borderColor: themes[theme].borderColor,
				maxWidth: '140px',
			}}
		>
			{availability.message2 ? (
				<p className="text-center text-xs">{availability.available ? availability.pincode : availability.message2}</p>
			) : null}

			<FontAwesomeIcon
				icon="fa-solid fa-location-dot"
				size="lg"
				color={
					themes[theme].primaryColor
					// availability.available
					// 	? themes[theme].primaryColor
					// 	: themes[theme].bgSecondaryColor
				}
			/>
		</div>
	);
};

export default Availability;
