import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { getAnalytics, logEvent } from 'firebase/analytics';

import partner from '../assets/icons/partner.svg';
import { set as setScreen } from '../store/screenSlice';

import themes from '../assets/themes';

const PartnerButton = () => {
  // const analytics = getAnalytics();

  const auth = useSelector((state) => state.auth);
  const theme = useSelector((state) => state.screen.theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div
      className="relative cursor-pointer flex items-center gap-2 border rounded-md py-1 px-4"
      style={{
        borderColor: themes[theme].borderTertiaryColor,
        color: themes[theme].secondaryColor,
      }}
      onClick={() => {
        // logEvent(analytics, 'header_partner_button_clicked', {
        //   authenticated: auth.authenticated,
        // });

        if (auth.authenticated) {
          navigate('/partner');
        } else {
          dispatch(setScreen({ showAuth: true }));
        }
      }}
    >
      <p className="text-xs text-center font-medium">
        Be Our
        <br />
        Partner
      </p>
      <img src={partner} alt="" className="w-10" />
    </div>
  );
};

export default PartnerButton;
