import { Link } from 'react-router-dom';
// import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
// import { getAnalytics, logEvent } from 'firebase/analytics';

import themes from '../assets/themes';

const ContactUs = () => {
  // const analytics = getAnalytics();

  const theme = useSelector((state) => state.screen.theme);

  const supportNumber = useSelector((state) => state.auth.supportNumber);

  // const [hover, setHover] = useState(false);

  // return <Link to={`https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=Hi Depo24, I have a question:\n`} target='_blank' >
  //     <div className="border-2 rounded-md p-2 flex justify-center items-center mb-2" style={{
  //         backgroundColor: themes[theme].bgColor,
  //         borderColor: themes[theme].borderColor,
  //     }}>
  //         <p className='font-semibold text-sm text-gray-500'>Contact US</p>
  //     </div>
  // </Link>

  return (
    <div
      className="text-sm py-2 px-4 transition-all ease-linear border rounded-md flex items-center gap-2 mb-1"
      style={{
        borderColor: themes[theme].borderTertiaryColor,
        color: themes[theme].secondaryColor,
      }}
      // onMouseEnter={() => {
      //     setHover(true)
      // }}
      // onMouseLeave={() => {
      //     setHover(false)
      // }}
    >
      <Link
        to={`https://wa.me/${supportNumber}?text=Hi Depo24, I have a question:\n`}
        target="_blank"
        className="font-medium"
        onClick={() => {
          // logEvent(analytics, 'contact_us_button_clicked', {});
        }}
      >
        Contact Us
      </Link>

      <FontAwesomeIcon
        icon="fa-solid fa-mobile-screen"
        color={themes[theme].secondaryColor}
        size="lg"
      />
    </div>
  );
};

export default ContactUs;
