const LargeSpinner = (props) => {
	return (
		<div
			className="flex justify-center items-center z-10 absolute top-0 left-0 w-full"
			style={{
				backgroundColor: 'rgba(255, 255, 255, 0.5)',
				height: props.height,
				color: props.color,
			}}
		>
			<div
				className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
				role="status"
			>
				<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
					Loading...
				</span>
			</div>
		</div>
	);
};

export default LargeSpinner;
