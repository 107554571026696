import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utilities/axiosInstance';
import Header from '../../containers/header';
import LargeSpinner from '../../components/largeSpinner';
import themes from '../../assets/themes';
import EstimateItem from '../../components/estimateItem';

function Estimates() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const auth = useSelector(state => state.auth)
    const screen = useSelector(state => state.screen)
    const theme = screen.theme;
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const getEstimates = async (page) => {
        setLoading(true);
        await axiosInstance.get(`/estimate?page=${page}`)
        .then((res) => {
            const data = res?.data?.data;
            setData(data);
        })
        .catch((err) => {
            setError(err.response?.data?.message || err.message);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        let page = 1;
        location.search.split(RegExp('[?&]')).forEach(item => {
            const [key, value] = item.split('=');
            if (key === 'page' && !isNaN(+value)) {
                page = +value;
            }
        })
        console.log(page);
        getEstimates(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);


    return (
        <div style={{ paddingTop: screen.screenWidth <= 920 ? `${screen.headerHeight}px` : '0px' }}>
            {screen.screenWidth <= 920 ? <Header /> : null}
            {auth.loading && <LargeSpinner color={themes[theme].primaryColor} height={'100%'} />}
            {!auth.loading && !auth.authenticated && <Navigate to="/auth/login" />}
            {auth.authenticated && (
                <main 
                    className="container" 
                    style={{ minHeight: `calc((var(--vh, 1vh) * 100) - ${screen.headerHeight}px)` }}
                >
                    <h2 className="text-lg font-semibold mb-4">My Estimates</h2>
                    <div className="relative">
                        {loading && <div className="w-full mt-32"><LargeSpinner color={themes[theme].secondaryColor} height={'100%'} /></div>}
                        {!loading && error && (
                            <div className="h-full flex justify-center items-center">
                                <p className="text-red-700">{error}</p>
                            </div>
                        )}
        
                        {!loading && !error && data ? data.estimates.length > 0 ? <div className="h-full flex flex-col items-center gap-8">
                            <div className="w-full flex flex-col items-stretch gap-4">
                                {data?.estimates?.map(estimate => {
                                    return ( <EstimateItem key={estimate.estimateId} estimate={estimate} /> )
                                })}
                            </div>
        
                            <div className='flex items-stretch justify-center border rounded-lg overflow-hidden'>
                                <button 
                                    type='button' 
                                    className='px-3 py-2 hover:bg-gray-200 disabled:bg-gray-100 disabled:text-gray-400' 
                                    disabled={data.page <= 1} 
                                    onClick={() => { navigate(`/profile/estimates?page=${data.page - 1}`) }}
                                >Prev</button>
                                {Array.from({ length: data.maxPage }, (_, i) => i + 1).map((page) => {
                                    return (
                                        (((page === 1) || (page === data.maxPage)) || ((page >= data.page - 2 && page <= data.page) || (page <= data.page + 2 && page >= data.page))) ? 
                                            <button 
                                                key={'pagination' + page} 
                                                className='px-3 py-2 text-center hover:bg-gray-200 disabled:border disabled:border-black disabled:text-gray-400' 
                                                disabled={data.page === page} 
                                                onClick={() => { navigate(`/profile/estimates?page=${page}`) }}
                                            >{page}</button> 
                                        : 
                                        ((page === data.page + 3 && page < data.maxPage) || (page === data.page - 3 && page > 1)) ? 
                                            <button type='button' className='px-3 py-2 disabled:text-gray-400' disabled>. . .</button> 
                                            : null
                                        )
                                })}
                                <button type='button' className='px-3 py-2 hover:bg-gray-200 disabled:bg-gray-100 disabled:text-gray-400' disabled={data.page >= data.maxPage} onClick={() => {
                                    navigate(`/profile/estimates?page=${data.page + 1}`);
                                }}>Next</button>
                            </div>
                        </div> : <div className="flex flex-col justify-center items-center gap-2 w-full mt-32">
                            <p className="">You haven't any requested estimates. Don't wait, start shopping now and enjoy fast delivery.</p>
                            <Link to='/' className="py-2 px-4 rounded-full font-medium text-sm text-white" style={{
                                backgroundColor: themes[theme].primaryColor,
                            }}>Shop Now</Link>
                        </div> : null}
                    </div>
                </main>
            )}
        </div>
    )
}

export default Estimates