import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { getAnalytics, logEvent } from 'firebase/analytics';

import cart from '../assets/icons/navigation/cartcopy.svg';
import themes from '../assets/themes';
import { set as setScreen } from '../store/screenSlice';

const CartButton = () => {
  // const analytics = getAnalytics();

  const auth = useSelector((state) => state.auth);
  const screen = useSelector((state) => state.screen);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div
      className="relative cursor-pointer"
      onClick={() => {
        // logEvent(analytics, 'cart_button_clicked', {
        //   authenticated: auth.authenticated,
        // });

        if (auth.authenticated) {
          navigate('/checkout/cart/order-cart');
        } else {
          dispatch(setScreen({ showAuth: true }));
        }
      }}
    >
      <img src={cart} alt="" className="h-6" />
      {auth.cartCount > 0 ? (
        <div
          className="absolute -top-2 -right-2 h-4 w-4 rounded-full flex items-center justify-center"
          style={{
            backgroundColor: themes[screen.theme].primaryColor,
          }}
        >
          <p
            className="text-white"
            style={{
              fontSize: auth.cartCount > 99 ? '0.5rem' : '0.6rem',
            }}
          >
            {auth.cartCount > 99 ? '+99' : auth.cartCount}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default CartButton;
