import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
// import { getAnalytics, logEvent } from 'firebase/analytics';

import themes from '../assets/themes';
import Modal from '../containers/modal';
import AP from '../assets/icons/Download_on_the_App_Store_Badge.svg.webp';
import GP from '../assets/icons/google-play-badge.jpg';
import phone from '../assets/icons/phone.svg';

const DownloadAppButton = () => {
  // const analytics = getAnalytics();

  const theme = useSelector((state) => state.screen.theme);
  const [showModal, setShowModal] = useState(false);
  // const [hover, setHover] = useState(false);

  // return <Link to={`/`} target='_blank' >
  //     <div className="border-2 rounded-md p-2 flex justify-center items-center mb-2" style={{
  //         backgroundColor: themes[theme].bgColor,
  //         borderColor: themes[theme].borderColor,
  //     }}>
  //         <p className='font-semibold text-sm text-gray-500'>Download App</p>
  //     </div>
  // </Link>

  return (
    <div
      className="text-sm py-2 px-2 transition-all ease-linear border rounded-md flex items-center gap-2 mb-1 cursor-pointer"
      style={{
        borderColor: themes[theme].borderTertiaryColor,
        color: themes[theme].secondaryColor,
      }}
      onClick={() => {
        // logEvent(analytics, 'download_app_button_clicked', {});
        setShowModal(!showModal);
      }}
      // onMouseEnter={() => {
      //     setHover(true)
      // }}
      // onMouseLeave={() => {
      //     setHover(false)
      // }}
    >
      <Modal active={showModal}>
        <div
          className="px-8 pt-4 pb-8 rounded-lg w-11/12 flex flex-col gap-2"
          onClick={(e) => e.stopPropagation()}
          style={{
            backgroundColor: themes[theme].primaryBGColor,
            maxWidth: '400px',
          }}
        >
          <div className="flex justify-between items-start">
            <div className="flex items-center gap-3 mt-8">
              <p
                className="uppercase text-sm font-medium"
                style={{
                  color: themes[theme].secondaryColor,
                }}
              >
                Download App
              </p>
            </div>

            <FontAwesomeIcon
              icon="fa-solid fa-xmark"
              size="lg"
              color={themes[theme].textColor}
              className="cursor-pointer"
              onClick={() => {
                setShowModal(!showModal);
              }}
            />
          </div>

          <div className="flex items-center gap-4">
            <Link
              to="https://play.google.com/store/apps/details?id=in.depo24.depo_24"
              target="_blank"
              aria-label="Get it from google play store"
            >
              <img src={GP} alt="" className="h-12" />
            </Link>

            <Link
              to="https://itunes.apple.com/us/app/itunes-connect/id376771144"
              target="_blank"
              aria-label="Get it from app store"
            >
              <img src={AP} alt="" className="h-12" />
            </Link>
          </div>
        </div>
      </Modal>
      <p target="_blank" className="font-medium">
        Download App
      </p>

      <img src={phone} alt="phone" />
    </div>
  );
};

export default DownloadAppButton;
