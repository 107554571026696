import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMagnifyingGlass as fasMagnifyingGlass,
  faCircleXmark as fasCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import { set as setSearch } from "../store/searchSlice";

import themes from "../assets/themes";
import axiosInstance from "../utilities/axiosInstance";
import { encodeUrlQueryParams } from "../utilities/encodeDecodeUrlParams";

library.add(fasMagnifyingGlass, fasCircleXmark);

const SearchBar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useSelector((state) => state.screen.theme);
  const search = useSelector((state) => state.search);
  const auth = useSelector((state) => state.auth);

  const [value, setValue] = useState(props.text ?? search.text);
  const [hasFocus, setHasFocus] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const ref = useRef();
  const ref2 = useRef();
  const timeout = useRef(null);

  useEffect(() => {
    if (props.text) {
      setValue(props.text);
    }
  }, [props]);

  const getTrendingSearches = async () => {
    axiosInstance.get("/others/trendingSearches").then((res) => {
      dispatch(setSearch({ trendingSearches: res.data.data }));
    });
  };

  const getSearchSuggestions = async () => {
    if (!value?.trim()) {
      return;
    }
    console.log("value : in keyword",value ,"condition :",!value)
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      axiosInstance.get(`/others/keywords/${value}`).then((res) => {
        dispatch(setSearch({ suggestions: res.data.data }));
      });
    }, 100);
  };

  const onSubmit = (e) => {
    e?.preventDefault();

    if (!value?.trim()) {
      return;
    }
    
    navigate(
      `/products?text=${encodeUrlQueryParams(value)}`
    );

    const currentValue = e.target.search.value;
  
    // Retrieve previous value (assume it's stored somewhere, e.g., a ref or state)
    const previousValue = e.target.search.getAttribute("data-previous-value") || "";
    e.target.search.setAttribute("data-previous-value", currentValue);

    // Check if value has changed
    if (previousValue === currentValue) {
      return; // Exit if values are the same
    }

    handleDataLayer(currentValue, 'manual_search')
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (e.target === ref.current || e.target === ref2.current) {
        return;
      } else {
        setShowSuggestion(false);
      }
    });

    return () => { };
  }, []);

  const handleDataLayer = (value, type) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
      'event':'search_interaction',
      'search_type': type,
      'search_term': value,
      'logged_in_medium': auth?.user ? 'mobile' : 'NA',
      'user_status': auth?.user ? 'logged_in' : 'guest',
      'custom_user_id': auth?.user ? auth?.user?.pgId : 'NA'
    });
	}

  return (
    <form
      className="flex-grow flex items-center border-2 rounded-md px-3 py-2 gap-3 relative w-full"
      style={{
        borderColor: !hasFocus ? 'transparent' : themes[theme].borderColor,
        backgroundColor: themes[theme].bgColor,
      }}
      onClick={() => ref.current.focus()}
      onSubmit={onSubmit}
    >
      <FontAwesomeIcon
        icon="fa-solid fa-magnifying-glass"
        color={themes[theme].bgSecondaryColor}
        size="lg"
        className="cursor-pointer"
        onSubmit={(e) => {
          e.stopPropagation();
          onSubmit();
        }}
      />

      <div className="flex-grow">
        <input
          ref={ref}
          value={value}
          type="text"
          name="search"
          className="focus:outline-none text-black bg-transparent text-sm w-full placeholder:text-black"
          placeholder="Search for products"
          onChange={(e) => {
            setValue(e.target.value);
            getSearchSuggestions();
          }}
          onFocus={() => {
            setHasFocus(true);
            setShowSuggestion(true);
            if (search.trendingSearches.length === 0) getTrendingSearches();
          }}
          onBlur={(e) => {
            setHasFocus(false);
            e.target.setAttribute("data-previous-value", e.target.value);
          }}
        />
      </div>

      <FontAwesomeIcon
        ref={ref2}
        icon="fa-solid fa-circle-xmark"
        color={themes[theme].bgSecondaryColor}
        size="lg"
        className="cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setValue("");
          if (!hasFocus) {
            ref.current.focus();
          }
        }}
      />

      <div
        className="absolute flex flex-col justify-start items-stretch overflow-hidden rounded shadow-lg transition z-20"
        style={{
          left: 0,
          right: 0,
          top: "calc(100% + 2px)",
          backgroundColor: themes[theme].primaryBGColor,
          maxHeight: "40vh",
          padding:
            (showSuggestion) &&
              (search.trendingSearches || search.suggestions)
              ? "0.75rem"
              : "0rem",
          height:
            (showSuggestion) &&
              (search.trendingSearches || search.suggestions)
              ? "fit-content"
              : 0,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="border-b pb-3"
          style={{
            borderColor: themes[theme].secondaryColor,
          }}
        >
          <h3
            className="font-medium uppercase"
            style={{
              color: themes[theme].secondaryColor,
            }}
          >
            Trending Searches
          </h3>

          <div className="flex items-center gap-2 justify-start overflow-scroll hide-scrollbar mt-2">
            {search.trendingSearches.map((trendingSearch) => (
              <div
                key={"Trending Search" + trendingSearch}
                className="rounded px-2 py-1 cursor-pointer min-w-fit"
                style={{
                  backgroundColor: themes[theme].tertiaryColor,
                }}
                onClick={() => {
                  navigate(
                    `/products?text=${encodeUrlQueryParams(trendingSearch)}`
                  );
                  handleDataLayer(trendingSearch, 'trending_search')
                }}
              >
                {trendingSearch}
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-2 mt-2 flex-grow overflow-y-scroll thin-scrollbar">
          {search.suggestions.map((suggestion) => (
            <p
              className="cursor-pointer"
              key={"Suggestion " + suggestion}
              style={{
                color: themes[theme].secondaryColor,
              }}
              onClick={() => {
                navigate(
                  `/products?text=${encodeUrlQueryParams(suggestion)}`
                );
                handleDataLayer(suggestion, 'suggested_search')
              }}
            >
              {suggestion}
            </p>
          ))}
        </div>
      </div>
    </form>
  );
};

export default SearchBar;
