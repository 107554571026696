import { useEffect, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
// import { getAnalytics, logEvent } from 'firebase/analytics';
import axiosInstance from '../utilities/axiosInstance';
import { Link } from 'react-router-dom';
import { encodeUrlQueryParams } from '../utilities/encodeDecodeUrlParams';
import { set as setHome } from '../store/homeSlice';

const BrowseByBrands = () => {
  // const analytics = getAnalytics();

  const [hover, setHover] = useState(false);
  const dispatch = useDispatch()
  const home = useSelector((state) => state.home);
  const screen = useSelector((state) => state.screen);
  const auth = useSelector((state) => state.auth);
  const [hoverL1, setHoverL1] = useState([]);
  const [brandsL1, setBrandsL1] = useState([]);
  const getBrands = async () => {
    if (home.brandsByL1) return;
    axiosInstance.get('/others/getBrandsByL1s').then((res) => {
      dispatch(
        setHome({
          brandsByL1: res.data.data,
        })
      );
    });
  };

  useEffect(() => {
    getBrands();
  }, []);
  useEffect(() => {
    if (home.brandsByL1) {
      setHoverL1(Object.keys(home.brandsByL1)?.[0]);
      setBrandsL1(Object.keys(home.brandsByL1));
    }
  }, [home.brandsByL1]);

  const handleDataLayer = (brand, l1, brandName) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
      'event':'header_interaction',
      'header_text': l1,
      'sub_header_text': brandName,
      'section_name': brand,
      'logged_in_medium': auth?.user ? 'mobile' : 'NA',
      'user_status': auth?.user ? 'logged_in' : 'guest',
      'custom_user_id': auth?.user ? auth?.user?.pgId : 'NA'
    });
	}

  return (
    <div
      className="text-sm py-2 px-1 transition-all ease-linear border-b-2"
      style={{
        borderColor: !hover ? 'transparent' : 'purple',
        // marginRight: screen.screenWidth <= 1070 ? '-1rem' : '-2rem'
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <Link
        to={`/brands`}
        className="font-medium"
        onClick={() => {
          // logEvent(analytics, 'browse_by_brands_button_clicked', {});
          handleDataLayer('Brands', 'NA', 'NA')
        }}
      >
        Brands
      </Link>

      <div
        className={`absolute right-0 top-full border rounded shadow-lg bg-white z-30 flex items-stretch gap-8 h-fit left-0 ${
          hover ? '' : 'hidden'
        }`}
        style={{
          minHeight: 'calc((var(--vh, 1vh) * 50)',
        }}
      >
        <div className="p-4">
          {brandsL1.length > 0 ? (
            brandsL1?.map((l1) => {
              return (
                <Link
                  to={`/products?text=${encodeUrlQueryParams(
                    l1
                  )}&filters=[{"field":"l1","value":"${encodeUrlQueryParams(
                    l1
                  )}"}]`}
                  key={l1 + 'headerBrandL1'}
                  className="text-sm block border-b border-gray-400 py-3 mb-2 cursor-pointer"
                  style={
                    l1 === hoverL1
                      ? {
                          fontWeight: '600',
                        }
                      : {}
                  }
                  onMouseEnter={() => {
                    setHoverL1(l1);
                  }}
                  onClick={() => {
                    handleDataLayer('Brands', l1, 'NA')
                  }}
                >
                  {l1}
                </Link>
              );
            })
          ) : (
            <div className="animate-pulse">
              <div className="space-y-2">
                <div className="py-3 mb-2">
                  <div className="h-4 bg-gray-300 rounded w-28 "></div>
                  <div className=" w-28 h-4 border-b border-gray-400 mb-2"></div>
                </div>
                <div className="mb-2">
                  <div className="h-4 bg-gray-300 rounded w-28 "></div>
                  <div className=" w-28 h-4 border-b border-gray-400 mb-2"></div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="grid grid-flow-row grid-cols-3 gap-x-12 gap-y-4 overflow-auto thin-scrollbar p-4">
            {home?.brandsByL1?.[hoverL1]?.length > 0
              ? home?.brandsByL1?.[hoverL1]?.map((brand) => {
                  return (
                    <Link
                      key={brand.title + 'k1Brand'}
                      to={`/products?filters=[{"field":"brandName","value":"${encodeUrlQueryParams(
                        brand.title
                      )}"}]`}
                      className="text-sm hover:text-purple-950"
                      onClick={() => { handleDataLayer('Brands', hoverL1, brand?.title)}}
                    >
                      {brand.title}
                    </Link>
                  );
                })
              : Array.from({ length: 10 }, (_, index) => (
                  <div className="animate-pulse py-3">
                    <div className="space-y-2">
                      <div className="mb-2">
                        <div className="h-4 bg-gray-300 rounded w-24 "></div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>

        <div
          className="bg-gray-100 p-8 flex-grow flex items-center justify-center"
          style={{
            alignItems:
              home?.brandsByL1?.[hoverL1]?.length < 3 ? 'start' : 'center',
            justifyContent:
              home?.brandsByL1?.[hoverL1]?.length < 3 ? 'start' : 'center',
          }}
        >
          <div className="grid gap-8 grid-flow-row grid-cols-2 minlg:grid-cols-3 minxl:grid-cols-4">
            {home?.brandsByL1?.[hoverL1]?.length > 0
              ? home?.brandsByL1?.[hoverL1]
                  ?.slice(
                    0,
                    screen.screenWidth >= 1280
                      ? 12
                      : screen.screenWidth >= 1024
                      ? 9
                      : 6
                  )
                  .map((brand) => {
                    return (
                      <Link
                        to={`/products?text=${encodeUrlQueryParams(
                          brand.title
                        )}&filters=[{"field":"brandName","value":"${encodeUrlQueryParams(
                          brand.title
                        )}"}]`}
                        aria-label={brand.title}
                        key={brand._id + 'header image'}
                        className="w-20 h-20"
                        onClick={() => { handleDataLayer('Brands', hoverL1, brand?.title)}}
                      >
                        <img
                          src={brand.image}
                          className="w-20 h-20 rounded"
                          alt={brand.title}
                        />
                      </Link>
                    );
                  })
              : Array.from({ length: 8 }, (_, index) => (
                  <div className="animate-pulse py-3">
                    <div className="space-y-2">
                      <div className="mb-2">
                        <div className="h-24 bg-gray-300 rounded w-24 "></div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowseByBrands;
