'use client'

import { useEffect, useState } from 'react'
import moment from 'moment'
import axiosInstance from '../../utilities/axiosInstance'
import EstimateDetails from '../../components/estimateDetailComponent'
import { useParams } from 'react-router-dom'

export default function RequestEstimateDetails() {
    const [estimateDetails, setEstimateDetails] = useState(null)
    const [attachments, setAttachments] = useState([])
    const [loading, setLoading] = useState(true)
    const { id } = useParams()

    const formattedDate = estimateDetails?.createdAt
        ? moment().diff(moment(estimateDetails.createdAt), 'days') <= 3
        ? moment(estimateDetails.createdAt).fromNow()
        : moment(estimateDetails.createdAt).format('DD MMM, YYYY')
        : ''

    useEffect(() => {
        const getEstimate = async () => {
            setLoading(true)
            try {
                const res = await axiosInstance.get(`/estimate/${id}`);
                const data = res.data.data;
                setEstimateDetails(data)
                if (data.attachments?.length) {
                const attachmentUrls = data.attachments2?.map((url, index) => ({
                    url,
                    name: data.attachments[index],
                }))
                setAttachments(attachmentUrls)
                }
            } catch (err) {
                console.error(err)
            } finally {
                setLoading(false)
            }
        }

        getEstimate()
    }, [id])

    if (loading) {
        return (
        <div className="flex justify-center items-center h-screen bg-white">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
        )
    }

    return (
        <div className="">
            <EstimateDetails
                estimateDetails={estimateDetails}
                attachments={attachments}
                formattedDate={formattedDate}
            />
        </div>
    )
}

