import { useState } from "react"
import LargeSpinner from "./largeSpinner";
import themes from "../assets/themes";
import { useSelector } from "react-redux";
import moment from "moment";
import changeImageURLDomain from "../utilities/changeImageURLDomain";

export default function EstimateDetails({ estimateDetails, attachments, formattedDate }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const theme = useSelector((state) => state.screen.theme);
    const getFileType = (url) => {
        const extension = url?.split('.')?.pop()?.toLowerCase()?.split('?')[0];
        console.log('extension', extension);
        if (['jpg', 'jpeg', 'png'].includes(extension)) {
        return 'image'
        } else if (extension === 'pdf') {
        return 'pdf'
        }
        return 'unknown'
    }

    return (
        <div className="container" style={{ width:'96%', maxWidth:'100%' }}>
            <h2 className="text-lg font-semibold mb-4">Estimate Details</h2>
            <div className="relative" style={{}}>
                {loading && ( <LargeSpinner color={themes[theme].secondaryColor} height={'100%'} /> )}

                {!loading && error && (
                <div className="h-full flex justify-center items-center">
                    <p className="text-red-700 font-medium">{error}</p>
                </div>
                )}

                {!loading && !error && estimateDetails && (
                    <div className="w-full flex flex-col gap-4 items-start [&>*]:w-full">
                        <div className='flex justify-between'>
                            <p className="font-medium text-[#243750]">
                                Order ID - {estimateDetails?.orderName}
                            </p>
                            <div className='flex items-center justify-between gap-2'>
                                <p className="text-xs rounded-2xl bg-slate-200 px-3 py-1">Request on : {moment(estimateDetails.createdAt).format('MMM DD, YYYY h:mm:ss A')}</p>
                                <p 
                                    className='text-xs py-1 px-3 rounded-2xl' 
                                    style={{ backgroundColor: estimateDetails?.status === 'Completed' ? '#d4edda' : '#fff0d4' }}
                                >{estimateDetails?.status}</p>
                            </div>
                        </div>

                        {/* List Of Estimate Products */}
                        {estimateDetails?.products?.length ? <div 
                            className="flex flex-col flex-grow gap-4 items-stretch p-4 rounded-lg mt-6"
                            style={{ boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.15)' }}
                        >
                            <p className='text-[#243750] font-[500]'>Products</p>
                            {estimateDetails?.products?.map((product, index) => {
                                return (
                                <div 
                                    key={product?.productId} 
                                    className="flex flex-col flex-grow gap-2 rounded-lg py-4 px-6 cursor-pointer items-stretch border" 
                                    
                                >
                                    <div className='flex justify-between w-[100%] text-xs' style={{ color: themes[theme].textColor }}>
                                        <div className="flex-grow flex flex-col gap-1 w-[50%]">
                                            <div className='flex gap-2'>
                                                <p className='mt-[5px]'>#{(index + 1)}.</p>
                                                <div>
                                                    <img
                                                    src={product?.mainImageUrl ? changeImageURLDomain(product?.mainImageUrl) : process.env.REACT_APP_Image_Comming_Soon}
                                                    alt=""
                                                    className="w-[75px] h-[75px] max-w-full"
                                                    />
                                                </div>
                                                <div>
                                                    <p className='text-[#243750] font-[500] py-1'>{product?.productName}</p>
                                                    <p className="pb-1">{product?.sku}</p>
                                                    <p className='text-[#243750] font-[500]'>₹{product?.price} x{" "}
                                                    {product?.quantity}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                );
                            })}
                        </div> :null}

                        {   /* List Of Attachments */}
                        {attachments?.length ? <div 
                            className="flex flex-col flex-grow gap-4 items-stretch p-4 rounded-lg mt-6"
                            style={{ boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.15)' }}
                        >
                            <p className='text-[#243750] font-[500]'>Attachments</p>
                            <div className="flex flex-row gap-6 flex-wrap">
                            {attachments?.map((attachment, index) => {
                                const fileType = getFileType(attachment?.url);
                                console.log('fileType', fileType);
                                return (
                                <div 
                                    key={index}
                                    className="flex flex-col rounded-lg cursor-pointer border w-32 text-xs overflow-hidden" 
                                    onClick={() => {
                                        const link = document.createElement('a');
                                        link.href = attachment?.url;
                                        link.target = '_blank';
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }}
                                >
                                    <div className="w-32 h-24 relative overflow-hidden rounded-md flex items-center justify-center">
                                        {fileType === 'image' ? (
                                            <img
                                                src={attachment?.url}
                                                alt=""
                                                className="max-w-full max-h-full"
                                            />
                                        ) : (
                                            <div className="text-4xl text-red-500">Pdf</div>
                                        )}
                                    </div>
                                    <p className='text-[#243750] font-[500] p-2 text-center truncate'>{attachment?.name}</p>
                                </div>
                                );
                            })}
                            </div>
                        </div> : null}

                        {/* Comments */}
                        {estimateDetails?.comment ? <div 
                            className="flex flex-col flex-grow gap-4 items-stretch p-4 rounded-lg mt-6"
                            style={{ boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.15)' }}
                        >
                            <p className='text-[#243750] font-[500]'>Comments</p>
                            <p className='text-[#243750] font-light'>{estimateDetails?.comment}</p>
                        </div>: null}

                    </div>
                )}
            </div>
        </div>
    )
}