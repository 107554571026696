import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import categories from '../assets/icons/navigation/categories.svg';
import categories2 from '../assets/icons/navigation/categories2.svg';
// import cart from '../assets/icons/navigation/cart.svg';
// import cart2 from '../assets/icons/navigation/cart2.svg';
import profile from '../assets/icons/navigation/profile.svg';
import profile2 from '../assets/icons/navigation/profile2.svg';
import orders from '../assets/icons/orders.svg';
import orders2 from '../assets/icons/orders.svg';

import themes from '../assets/themes';

const HoverNav = () => {
    const screen = useSelector(state => state.screen);
    // const auth = useSelector(state => state.auth);

    return <nav className='rounded shadow p-6 hidden absolute left-0 flex-col items-start gap-6 bg-white z-20 w-40' style={{
        top: 'calc(100% + 2px)',
    }}>

        <NavLink to={'/categories'} className='flex items-center gap-2 hover:[&>p]:font-semibold'>
            {({ isActive }) => {
                return <Fragment>
                    <img src={isActive ? categories2 : categories} alt='' className='w-4' />
                    <p className='font-medium text-xs' style={{
                        color: themes[screen.theme].secondaryColor,
                    }}>Categories</p>
                </Fragment>
            }}
        </NavLink>

        <NavLink to={'/profile'} className='flex items-center gap-2 hover:[&>p]:font-semibold'>
            {({ isActive }) => {
                return <Fragment>
                    <img src={isActive ? profile2 : profile} alt='' className='w-4' />
                    <p className='font-medium text-xs' style={{
                        color: themes[screen.theme].secondaryColor,
                    }}>Profile</p>
                </Fragment>
            }}
        </NavLink>

        <NavLink to={screen.screenWidth <= 920 ? '/orders' : '/profile/orders'} className='flex items-center gap-2 hover:[&>p]:font-semibold'>
            {({ isActive }) => {
                return <Fragment>
                    <img src={isActive ? orders2 : orders} alt='' className='w-4' />
                    <p className='font-medium text-xs' style={{
                        color: themes[screen.theme].secondaryColor,
                    }}>Orders</p>
                </Fragment>
            }}
        </NavLink>

        {/* <NavLink to={'/checkout/cart/order-cart'} className='flex items-center gap-2'>
            {({ isActive }) => {
                return <Fragment>
                    <div className="relative">
                        <img src={isActive ? cart2 : cart} alt='' className='w-4' />
                        {auth.cartCount > 0 ? <div
                            className="absolute -top-2 -right-2 h-3 w-3 rounded-full flex items-center justify-center"
                            style={{
                                backgroundColor: themes[screen.theme].primaryColor,
                            }}
                        >
                            <p className="text-white" style={{
                                fontSize: auth.cartCount > 99 ? '0.4rem' : '0.5rem',
                            }}>{auth.cartCount > 99 ? '+99' : auth.cartCount}</p>
                        </div> : null}
                    </div>
                    <p className='font-medium text-xs' style={{
                        color: themes[screen.theme].secondaryColor,
                    }}>Cart</p>
                </Fragment>
            }}
        </NavLink> */}
    </nav>
};

export default HoverNav;